import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react';
import CTA from '../../components/CTA';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import TumultBeatingHeart from '../../components/integrations/TumultBeatingHeart';
import Link from '../../components/link';
import Layout from '../../layout';

const styles = css`
  .items {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 4rem;
    max-width: 54rem;
    flex-wrap: wrap;
    margin: 0 auto 5rem;
    padding: 0 1rem;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;

    text-align: center;
    width: 24rem;

    & svg {
      border-radius: 100px;
      background: #107db522;
      padding: 10px;
      color: #107db5;
    }
  }

  .buttonContainer {
    text-align: center;
  }
  .primaryButton {
    pointer-events: all;
    margin: 0;
    font-size: calc(14px + 0.5vw);
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
    background: #107db5;
    border: 0px solid #107db5;
    padding: 0.33em 1rem;
    border-radius: 2rem;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    margin: 2rem auto;

    & span {
      position: relative;
      z-index: 1;
    }
    & svg {
      vertical-align: text-bottom;
      margin-left: 0.5rem;
    }

    &::before {
      content: ' ';
      display: block;
      background: linear-gradient(135deg, #4ca2cd, #67b26f);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      border-radius: 8px;
    }

    @media (min-width: 600px) {
      font-size: 16px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      line-height: 40px;
      height: 40px;
    }

    &:hocus {
      text-decoration: none;
      &::before {
        opacity: 1;
      }
    }
  }
`;

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Tumult Hype integration" keywords={['']} pathname={this.props.location.pathname} />

        <PageHeader>
          <TumultBeatingHeart />
          <h1>Preview your Tumult Hype designs inside Polypane</h1>
          <p className={styles.buttonContainer}>
            <Link to="/integrations/">See all integrations</Link>
          </p>
        </PageHeader>

        <div className={styles.items}>
          <div className={styles.item}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-layout-cards"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentcolor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="4" y="4" width="6" height="16" rx="2" />
              <rect x="14" y="4" width="6" height="10" rx="2" />
            </svg>
            <h2>Automatic panes</h2>
            <p>
              Click the preview in Polypane button in Hype to automatically create panes in Polypane from your viewport
              sizes.
            </p>
          </div>
          <div className={styles.item}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-rotate-rectangle"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentcolor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M16.3 5h.7a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h5l-2.82 -2.82m0 5.64l2.82 -2.82"
                transform="rotate(-45 12 12)"
              />
            </svg>
            <h2>Refresh existing tabs</h2>
            <p>
              Click the preview button again and Polypane focuses and reloads your existing tab, even if you've made
              changes to that tab.
            </p>
          </div>
        </div>
        <div className="page childPadding">
          <div
            style={{
              position: 'relative',
              width: '100%',
              paddingTop: '56.25%',
              borderRadius: 4,
              overflow: 'hidden',
              boxShadow:
                'rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.3) 0px 50px 100px -20px, rgba(0, 0, 0, 0.5) 0px 30px 60px -30px, rgba(103, 178, 111, 0.3) -10px 10px 60px -10px',
            }}
          >
            <iframe
              title="preview of Hype integration"
              src="https://www.youtube.com/embed/rJ3ON_oSL_g?&amp;autoplay=false&amp;start=0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              frameBorder="0"
            />
          </div>

          <p className={styles.buttonContainer}>
            <a className={styles.primaryButton} href="https://tumult.com/hype/">
              <span>
                Get Tumult Hype
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-external-link"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#ffffff"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
                  <line x1="10" y1="14" x2="20" y2="4" />
                  <polyline points="15 4 20 4 20 9" />
                </svg>
              </span>
            </a>
          </p>

          <h2 id="setup">Setup instructions</h2>
          <p>How to use Tumult Hype with Polypane.</p>

          <h3>Step 1: Click the preview button in Hype.</h3>
          <p>
            If your preview button doesn't yet show Polypane, click the down arrow and select Polypane from the list.
            This will set Polypane as your default preview browser and open Polypane.
          </p>
        </div>
        <StaticImage
          src="../../../assets/images/integrations/screens/hype/hype1.png"
          alt="The Hype application with the preview button highlighted"
          className="ig-imgshadow"
        />

        <div className="page childPadding">
          <h3>Step 2: Polypane shows your project in all your layouts.</h3>
          <p>
            The layouts you configured in Tumult Hype are now opened as panes in Polypane, so you can test all layouts
            in one overview.
          </p>

          <p>
            You can edit these panes how you like (add rulers, debug tools, or even add and removes panes), and the next
            time you preview from Hype, Polypane will reload the pages but keep the edits you made to the panes.
          </p>
        </div>
        <StaticImage
          src="../../../assets/images/integrations/screens/hype/hype2.png"
          alt="Polypane showing the panes imported from Hype"
          className="ig-imgshadow"
        />

        <div className="page childPadding">
          <p className={styles.buttonContainer}>
            <Link to="/integrations/">Back to integrations overview</Link>
          </p>
        </div>
        <CTA />
      </Layout>
    );
  }
}

export default Page;
